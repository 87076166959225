import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/runner/_work/designsystem-docs/designsystem-docs/src/templates/MDXPage.jsx";
import bytteIkonFigma from "../../../static/img/ikoner/bytte-ikon-figma.png";
import nyttIkonFigma from "../../../static/img/ikoner/nytt-ikon-figma.png";
import bytteFargeFigma from "../../../static/img/ikoner/bytte-farge-figma.png";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DSExample = makeShortcode("DSExample");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Bruk av ikoner`}</h2>
    <p>{`Ikoner skal gjøre det enkelt for kunden å navigere i de digitale flatene våre. Ikonene representerer funksjoner, verktøy eller handlinger og visualiserer funksjonalitet.`}</p>
    <p>{`Ikonene vi bruker kommer fra ikonbiblioteket Material Symbols. `}<a parentName="p" {...{
        "href": "/profil/ikoner/"
      }}>{`Les mer om ikonene`}</a>{` under profil.`}</p>
    <h2>{`Figma`}</h2>
    <p>{`Når du bruker komponenter fra designsystem-biblioteket i Figma ligger ikonene tilgjengelig som en del av komponentene.`}</p>
    <p>{`For å bytte ikon klikker du deg bare inn på ikonet og skriver navnet på ikonet du vil ha. Du får oversikt over alle ikonene og navnene med plugin «Material Symbols» i Figma eller på `}<a parentName="p" {...{
        "href": "https://fonts.google.com/icons?icon.style=Rounded"
      }}>{`https://fonts.google.com/icons?icon.style=Rounded`}</a>{`.`}</p>
    <img src={bytteIkonFigma} alt="Illustrasjon av hvordan man bytter ikon i Figma" className="sb1ds-layout__spacing-bottom--md" />
    <p>{`Når du trenger et ikon utenfor komponentene lager du et nytt tekstelement, skriver navnet på ikonet, og velger en av tekststilene for ikoner under Text Styles.`}</p>
    <img src={nyttIkonFigma} alt="Illustrasjon av hvordan man velger tekststil for ikoner" className="sb1ds-layout__spacing-bottom--md" />
    <p>{`Bruk Color Styles for å bytte farge på ikonet.`}</p>
    <img src={bytteFargeFigma} alt="Illustrasjon av hvordan man velger color style for ikonet" className="sb1ds-layout__spacing-bottom--md" />
    <h2>{`SVG-implementasjon`}</h2>
    <p>{`Bruk pakkene `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ffe-icons`}</code>{` og `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ffe-icons-react`}</code>{`. I `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ffe-icons`}</code>{` finner du alle varianter av ikonene som SVG-filer, i tillegg til styling-filene som brukes i React-komponenten. SVG-filene er sortert på `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`weight`}</code>{`, om de er fylt eller ikke, samt størrelse. Hvilken størrelse du skal bruke kan variere fra komponent til komponent.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`Icon`}</code>{`-komponenten tar inn en filbane eller `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/Data_URLs"
      }}>{`data-URL`}</a>{`, og setter SVG-ikonet som en mask. Det gjør at ikonet kan arve `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`color`}</code>{`-verdien fra et parent-element.`}</p>
    <p>{`Filbane er anbefalt metode for å importere ikoner. På denne måten kan man i tillegg til selve ikonet spesifisere variant, vekt og størrelse i URL-en ikonet hentes fra. For eksempel vil et `}<em parentName="p">{`åpent`}</em>{` hjem-ikon med vekt `}<em parentName="p">{`300`}</em>{` og størrelse `}<em parentName="p">{`md`}</em>{` kunne tas i bruk slik: `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`import homeIcon from '@sb1/ffe-icons/icons/open/300/md/home.svg';

return <Icon fileUrl={homeIcon} size="md" ariaLabel="hjem" />;`}</code></pre></div>
    <p>{`Dersom man ønsker å legge inn ikoner som data-URL må man selv konvertere ikonene til et passende format, for eksempel `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Glossary/Base64"
      }}>{`base64`}</a>{`.`}</p>
    <DSExample name="icons_Icon" mdxType="DSExample" />
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`mask`}</code>{` fungerer på samme måte som bakgrunn. Det er derfor viktig at du setter en `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`size`}</code>{`-prop som stemmer overens med SVG-størrelsen, slik at ikonet vises riktig. Setter du en verdi i `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`size`}</code>{` som `}<em parentName="p">{`ikke`}</em>{` stemmer overens med SVG-en, vil resultatet se slik ut:`}</p>
    <DSExample name="icons_Icon_wrong_size" mdxType="DSExample" />
    <p>{`Dette er gjort med vilje for at man skal bli klar over at man bruker feil SVG, da linjetykkelsen varierer fra størrelse til størrelse.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`aria-hidden`}</code>{` settes automatisk til `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`true`}</code>{`, dersom du ikke sender med en verdi i `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ariaLabel`}</code></p>
    <DSExample name="icons_Icon_ariahidden" mdxType="DSExample" />
    <h2>{`Stil`}</h2>
    <p>{`Vi bruker stilen «rounded». Den harmonerer godt med resten av den visuelle identiteten til SpareBank 1.`}</p>
    <div className="sb1ds-icon-example"><DSExample name="icons_Icon" mdxType="DSExample" /></div>
    <h2>{`Størrelser`}</h2>
    <p>{`Du kan velge blant 4 standardstørrelser: sm, md, lg, xl.`}</p>
    <div className="sb1ds-icon-example"><DSExample name="icons_Icon_allsizes" mdxType="DSExample" /></div>
    <h2>{`Tykkelser`}</h2>
    <p>{`Velg mellom tykkelsene 300, 400 og 500.`}</p>
    <div className="sb1ds-icon-example"><DSExample name="icons_Icon_allweights" mdxType="DSExample" /></div>
    <h2>{`Farger`}</h2>
    <p>{`Disse fargene brukes som standard:`}</p>
    <ul>
      <li parentName="ul">{`Light mode: Vann, Fjell, Hvit`}</li>
      <li parentName="ul">{`Dark mode: Vann 70%, Vann 30%, Svart`}</li>
    </ul>
    <p>{`Du kan også velge fra de andre fargene i SpareBank 1-paletten.`}</p>
    <h2>{`Fyll`}</h2>
    <p>{`Ikonene brukes som hovedregel uten fyll. I noen tilfeller kan vi trenge fylte ikoner, for eksempel for å vise at et element er valgt.`}</p>
    <div className="sb1ds-icon-example"><DSExample name="icons_Icon_filled" mdxType="DSExample" /></div>
    <h2>{`Universell utforming`}</h2>
    <p>{`Løsningene våre blir brukt med ulike typer hjelpeteknologi. For at ikonene skal fungere godt med hjelpeteknologi som for eksempel skjermlesere er det viktig at de enten har tektsbeskrivelse, eller er tilstrekkelig skjult for hjelpeteknolgi i de tilfellene hvor det gir den beste brukeropplevelsen.`}</p>
    <p>{`Når ikonet skal presenteres av hjelpeteknologi legger du inn en beskrivende `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aria-label`}</code>{` gjennom `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ariaLabel`}</code>{`-propertyen.`}</p>
    <p>{`Ikonene har ARIA `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`role="graphics-symbol img"`}</code>{`, som gjør at de presenteres som grafikksymboler. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`"img"`}</code>{` gjør at ikonene leses opp som bilder i tilfeller hvor `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`"graphics-symbol"`}</code>{` ikke støttes.`}</p>
    <p>{`Når ikonet skal skjules for hjelpeteknologi lar du være å sende med  `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ariaLabel`}</code>{` eller setter den til `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{null}`}</code>{`· Ikonet får da `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`aria-hidden="true"`}</code>{` og vil ikke presenteres av hjelpeteknologi.`}</p>
    <p>{`Eksempel på tilfelle hvor ikonet skjules: Ikonet ligger i en knapp. Knappeteksten eller aria-labelet til knappen er så beskrivende for hva knappen gjør at aria-label på ikonet blir overflødig og bidrar til unødvendig støy med skjermlesere og annen hjelpeteknologi.`}</p>
    <div className="sb1ds-icon-example"><DSExample name="icons_Icon_arialabel" mdxType="DSExample" /></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      